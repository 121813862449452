import axios from '@/axios'
import * as types from '../mutation-types'

export default {
  namespaced: true,
  state: {
    branches: []
  },

  actions: {
    sendFeedback ({ commit }, { name, email, type, body }) {
      return axios
        .post('/feedback', {
          name,
          email,
          type,
          body
        })
        .then((response) => {
          return response.data
        })
    },
    fetchAll ({ commit }) {
      return axios.get('/branches').then((response) => {
        commit(types.STORE_BRANCHES, response.data)
        return response.data
      })
    }
  },

  mutations: {
    [types.STORE_BRANCHES] (state, branches) {
      state.branches = branches
    }
  },

  getters: {}
}
