import axiosInstance from 'axios'
import i18n from './i18n/i18n'
import { BASE_URL } from './constants'

const axios = axiosInstance.create({
  baseURL: BASE_URL + '/api',
  headers: { common: { 'accept-language': i18n.locale } }
})

export default axios
