import axios from '@/axios'
import * as types from '../mutation-types'

export default {
  namespaced: true,

  state: {
    products: []
  },

  actions: {

    fetchAll ({ commit }) {
      return axios.get('/products').then((response) => {
        commit(types.STORE_PRODUCTS, response.data)
        return response.data
      })
    },

    fetch ({ commit }, { slug }) {
      return axios.get(`/products/${slug}`).then((response) => {
        commit(types.STORE_ONE_PRODUCT, response.data)
      })
    }

  },

  mutations: {
    [types.STORE_PRODUCTS] (state, products) {
      state.products = products
    }
  },

  getters: {
    getById: (state) => (id) => state.products.find((t) => t.id === id),
    getBySlug: (state) => (slug) => state.products.find((t) => t.slug === slug)
  }
}
