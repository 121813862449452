<template>
  <div class="col-md-4 col-sm-6 text-center news">
    <router-link class="text-decoration-none" :to="{ name: 'report' , params: { slug: oneNews.slug } }">
        <img class="w-100 rounded-4" height="250px" :src="oneNews.primaryImage.url" alt="news">
        <span class="px-2 fw-semibold">{{ oneNews.title }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NewsVue',
  props: ['oneNews']
}
</script>

<style>

</style>
