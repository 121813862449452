<template>
  <div class="container text-center">
    <div class="loading-overlay">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoader',
  mounted () {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.isLoader = true
      }
    }
  }
}
</script>

<style lang="scss">
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    color: var(--bs-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
</style>
