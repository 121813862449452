<template>
  <div class="images container py-7">
    <div class="d-flex flex-wrap gap-2 justify-content-center">
      <img
        v-for="(img, i) in images"
        :key="i"
        :src="img"
        class="image-item rounded-4"
        width="30%"
        @click="index = i; visible = true"
      >
      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="images"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ImagesView',
  components: { VueEasyLightbox },
  data () {
    return {
      visible: false,
      index: 0,
      loading: false
    }
  },

  methods: {
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    }
  },

  computed: {
    ...mapGetters({
      getAlbumsById: 'albums/getById'
    }),
    ...mapState({
      albums: (state) => state.albums.albums
    }),

    images () {
      const images = this.getAlbumsById(+this.$route.params.id)?.images
      if (!images) return []
      return images.map((image) => image.url)
    }
  },

  created () {
    this.loading = true
    if (!this.albums.length) {
      this.$store.dispatch('albums/fetchAll').finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.images {
  min-height: 80vh;
}
  .image-item {
    cursor: pointer;
  }
</style>
