<template>
  <router-link :to="{ name: 'report', params: { slug: oneNews.slug } }" class="col-lg-12 col-md-6 text-decoration-none">
    <div class="row rounded-4 mb-3 d-flex align-items-center">
      <div class="image col-lg-7 col-7">
        <img :src="oneNews.primaryImage.url" class="w-100 rounded-3 h-100" alt="news">
      </div>
      <div class="col-lg-5 col-5 content mt-4 d-flex flex-column justify-content-center ps-2">
        <h5 class="title">{{ oneNews.title }}</h5>
        <p v-html="oneNews.summary"></p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ReportCard',
  data () {
    return {

    }
  },
  props: ['oneNews']
}
</script>

<style lang='scss' >
  .content {
    font-size: 13px;
    .title {
      font-size: 16px;
    }
  }
</style>
