import axios from '@/axios'
import * as types from '../mutation-types'

export default {
  namespaced: true,

  state: {
    aboutus: []
  },

  mutations: {
    [types.STORE_ABOUT] (state, about) {
      state.aboutus = about
    }
  },

  actions: {
    fetchAll ({ commit }) {
      return axios.get('/aboutus').then((response) => {
        commit(types.STORE_ABOUT, response.data)
      })
    }
  },

  getters: {
    getById: (state) => (id) => state.about.find((t) => t.id === id)
  }
}
