<template>
  <div class="contact-us pt-7 pb-5">
    <div class="container">
      <div class="card border rounded-4 bg-white d-flex px-4">
        <div class="row pt-lg-5 pt-4">
          <div class="col-md-6 order-lg-1 order-md-1 order-sm-2 order-2">
            <form class="px-4">
              <div class="form-group">
                <label class="label">{{$t('your-name')}}</label>
                <input v-model="name" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('full-name')">
              </div>
              <div class="form-group">
                <label class="label">{{$t('your-email')}}</label>
                <input v-model="email" type="email" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('email-address')">
              </div>
              <div class="form-group">
                <label class="label">{{$t('type')}}</label>
                <input v-model="type" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('select-type')">
              </div>
              <div class="form-group">
                <label class="label">{{$t('your-message')}}</label>
                <textarea v-model="message" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('message')"></textarea>
              </div>
              <a @click="submit()" class="btn btn-info w-0 mx-auto text-white mb-4">{{$t('send')}}</a>
            </form>
          </div>
          <div class="col-md-6 order-lg-2 order-md-2 order-sm-1 order-1 ps-4 pb-4">
            <h2 class="fs-5 fw-light text-secondary">{{$t('contact-us')}}</h2>
            <h3 class="fs-2 fw-bold mb-6 title-3">{{$t('get-touch-with-us')}}</h3>
            <h4 class="details">{{$t('customer-service')}}</h4>
            <span class="details-info">{{ branches[0]?.contacts?.phone }}</span>
            <h4 class="details">{{$t('address')}}</h4>
            <span class="details-info">{{ branches[0]?.contacts?.address }}</span>
            <h4 class="details">{{$t('opening-hours')}}</h4>
            <span class="details-info">{{ branches[0]?.openHours }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ContactView',
  data () {
    return {
      name: null,
      email: null,
      type: null,
      message: null
    }
  },
  computed: {
    ...mapState({
      branches: (state) => state.contactus.branches
    })
  },
  created () {
    if (!this.branches.length) {
      this.$store.dispatch('contactus/fetchAll')
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us {
  transform: translate(0);
  min-height: 80vh;
  .details {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 25px;
  }
  .details-info {
    font-size: 12px;
    color: #bebebe;
  }
  .label {
    font-size: 12px;
    margin-left: 5px;
  }
  input::placeholder {
    font-size: 12px;
  }
  textarea::placeholder {
    font-size: 12px;
  }
}
</style>
