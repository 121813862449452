<template>
  <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
    <router-link :to="{ name: 'images', params: { id: album.id } }" class="text-decoration-none">
      <img :src="album.primaryImage?.url" class="rounded-4 w-100 h-100" alt="album">
      <h5 class="text-center fs-6 pt-2">{{album.title}}</h5>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AlbumVue',
  props: ['album']
}
</script>

<style>

</style>
