<template>
    <div id="app">
        <PageLoader v-if="loading"/>
        <template v-else>
          <NavBar :class="$i18n.locale === 'ar' ? 'arabic' : 'english'"/>
          <router-view :class="$i18n.locale === 'ar' ? 'arabic' : 'english'"/>
          <FooterVue :branches="branches" :class="$i18n.locale === 'ar' ? 'arabic' : 'english' "/>
        </template>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooterVue from '@/components/FooterVue.vue'
import PageLoader from '@/components/PageLoader.vue'
import { changeLang } from './i18n/i18n'
import axios from '@/axios'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      loading: false
    }
  },

  components: {
    PageLoader,
    NavBar,
    FooterVue
  },

  computed: {
    ...mapState({
      branches: (state) => state.contactus.branches
    })
  },

  watch: {
    '$i18n.locale' (newLocale) {
      changeLang(newLocale)
      axios.defaults.headers.common['accept-language'] = newLocale
      this.fetchData()

      this.loading = true
      setTimeout(() => { this.loading = false }, 1000)
    }
  },

  methods: {
    fetchData () {
      this.loading = true
      Promise.all([
        this.$store.dispatch('categories/fetchAll'),
        this.$store.dispatch('categories/fetchTree'),
        this.$store.dispatch('aboutus/fetchAll'),
        this.$store.dispatch('products/fetchAll'),
        this.$store.dispatch('news/fetchAll'),
        this.$store.dispatch('sliders/fetchAll'),
        this.$store.dispatch('albums/fetchAll'),
        this.$store.dispatch('contactus/fetchAll')
      ]).finally(() => { this.loading = false })
    }
  },

  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

#app {
  .arabic {
    font-family: "Cairo", sans-serif;
  }
  .english {
    font-family: "Urbanist", sans-serif;
  }
}

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
</style>
