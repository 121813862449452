<template>
    <nav class="navbar navbar-dark navbar-expand-lg bg-primary p-0 rounded-bottom-5 fixed-top">
        <div class="container py-2">
            <router-link class="navbar-brand p-0" :to="{ name: 'home' }"
                ><img height="90" src="../assets/images/logo-en.png" alt="logo"
            /></router-link>
            <button
                :class="['navbar-toggler', { collapsed: isNavbarOpen } ]"
                @click="isNavbarOpen = !isNavbarOpen"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                :aria-expanded="isNavbarOpen ? 'true' : 'false'"
                aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse text-center"
              :class="[ { show: isNavbarOpen }]" id="navbarNavAltMarkup">
              <ul class="navbar-nav ms-auto d-flex flex-row flex-wrap justify-content-center">
                <li class="nav-item" @click="isNavbarOpen = false">
                  <router-link class="nav-link text-white fw-light" :to="{ name: 'home'}">
                    {{$t('home')}}
                  </router-link>
                </li>
                <li class="nav-item" @click="isNavbarOpen = false">
                  <router-link class="nav-link text-white fw-light" :to="{ name: 'products'}">
                    {{$t('products')}}
                  </router-link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle text-white fw-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    :to="{ name: 'products'}"
                  >
                    {{$t('category')}}
                  </a>
                  <ul class="dropdown-menu">
                    <li v-for="category in categoriesTree" :key="category.id" class="dropdown-item">
                      <router-link
                        class="dropdown-item nav-item"
                        :to="{ name: 'products', query: { category: category.slug } }"
                      >
                        {{ category.name }}
                      </router-link>

                      <ul v-if="category.children && category.children.length" class="dropdown-submenu">
                        <li v-for="child in category.children" :key="child.id">
                          <router-link
                            class="dropdown-item nav-item"
                            :to="{ name: 'products', query: { category: child.slug } }"
                          >
                            {{ child.name }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="nav-item" @click="isNavbarOpen = false">
                  <router-link class="nav-link text-white fw-light" :to="{ name: 'news'}">
                    {{$t('news')}}
                  </router-link>
                </li>
                <li class="nav-item" @click="isNavbarOpen = false">
                  <router-link class="nav-link text-white fw-light" :to="{ name: 'gallery'}">{{$t('gallery')}}</router-link>
                </li>
                <li class="nav-item" @click="isNavbarOpen = false">
                  <router-link class="nav-link text-white fw-light" :to="{ name: 'contact'}">{{$t('contact')}}</router-link>
                </li>
                <li class="nav-item" @click="isNavbarOpen = false">
                  <router-link class="nav-link text-white fw-light" :to="{ name: 'about'}">{{$t('about')}}</router-link>
                </li>
              </ul>
              <span class="me-2"><i class="fas fa-search text-white"></i></span>
              <button @click="$i18n.locale = 'en'" :class="{'active': $i18n.locale === 'en' }" class=" flag btn btn-outline-inherit border-0 p-0 ms-2" type="submit">
                <img src="../assets/images/flag/flag-united-state.png" width="95%" class="img-flag" height="25px" alt="EN">
              </button>
              <button @click="$i18n.locale = 'ar'" :class="{'active': $i18n.locale === 'ar' }" class=" flag btn btn-outline-inherit border-0 p-0 ms-2" type="submit">
                <img src="../assets/images/flag/united-arab-emirates-flag.png" width="100%" height="25px" class="img-flag rounded-circle" alt="AR">
              </button>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      isNavbarOpen: false,
      loading: false
    }
  },

  computed: {
    ...mapState({
      categoriesTree: (state) => state.categories.categoriesTree
    })
  }
}
</script>

<style lang="scss">
.nav-item {
  .dropdown-menu {
    .dropdown-submenu {
      li::marker {
        content: none;
      }
    }
  width: 300px !important;
  background-color: var(--bs-primary);
  .dropdown-item {
    direction: ltr !important;
    color: white;
    &:hover {
      color: var(--bs-secondary);
      background-color: transparent;
    }
  }
}
}
  .navbar .container {
    .navbar-nav .nav-link{
      font-weight: light;
      font-size: 14px;
      padding-right: 40px;
    }
    .navbar-nav .nav-link:hover {
      color: #FFB659 !important;
    }
    .nav-link {
      &.router-link-exact-active {
          color: #FFB659 !important;
      }
      @media (max-width: 768px) {
        padding: 10px 20px;
        align-items: center;
      }
    }
    .flag {
     width: 25px;
  }
}

.active {
  .img-flag {
    filter:
      drop-shadow( 2px  0px 0px #f7941d)
      drop-shadow(-2px  0px 0px #f7941d)
      drop-shadow( 0px  2px 0px #f7941d)
      drop-shadow( 0px -2px 0px #f7941d);
  }
}

</style>
