import HomeView from '../views/HomeView.vue'
import NewsView from '../views/NewsView.vue'
import GalleryView from '../views/GalleryView.vue'
import ContactView from '../views/ContactView'
import AboutView from '../views/AboutView.vue'
import ReportView from '../views/ReportView.vue'
import ImagesView from '../views/ImagesView'
import ProductsView from '../views/ProductsView.vue'
import ProductView from '../views/ProductView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/report/:slug',
    name: 'report',
    component: ReportView
  },
  {
    path: '/images/:id',
    name: 'images',
    component: ImagesView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/product/:slug',
    name: 'product',
    component: ProductView
  }
]

export default routes
