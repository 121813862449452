import axios from '@/axios'
import * as types from '../mutation-types'

export default {
  namespaced: true,

  state: {
    news: []
  },

  actions: {
    fetchAll ({ commit }) {
      return axios.get('/news').then((response) => {
        commit(types.STORE_NEWS, response.data)
      })
    },

    fetch ({ commit }, { id }) {
      return axios.get(`/news/${id}`).then((response) => {
        commit(types.STORE_ONE_NEWS, response.data)
      })
    }
  },

  mutations: {
    [types.STORE_NEWS] (state, news) {
      state.news = news
    }
  },

  getters: {
    getById: (state) => (id) => state.news.find((t) => t.id === id),
    getBySlug: (state) => (slug) => state.news.find((t) => t.slug === slug)
  }
}
