<template>
  <div class="news pt-7 pb-4 container">
    <h1>{{$t('news')}}</h1>
    <PageLoader v-if="!loading"/>
    <div v-if="loading" class="row pb-5">
      <NewsVue v-for="oneNews in news" :key="oneNews.id" :oneNews="oneNews" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'
import NewsVue from '@/components/NewsVue.vue'

export default {
  name: 'NewsView',
  components: { PageLoader, NewsVue },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      news: (state) => state.news.news
    })
  },
  created () {
    if (!this.news.length) {
      this.loading = false
      this.$store.dispatch('news/fetchAll').finally(() => { this.loading = true })
    }
  }
}
</script>

<style lang="scss" scoped>
 .news {
  min-height: 80vh;
 }
  .card-title {
    font-size: 20px;
  }
  .card-text {
    font-size: 14px;
  }
  @media (max-width: 578px) {
    .news {
        width: 75% ;
        margin: 0 auto;
    }
  }
</style>
