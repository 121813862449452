<template>
  <div class="products container pt-7 pb-5">
    <h1 class="ms-2">{{$t('products')}}</h1>
    <PageLoader v-if="!loading"/>
    <h1 v-if="!filteredProducts.length" class="text-center pt-5">{{$t('no-result')}}</h1>
    <div v-if="loading"  class="row">
      <ProductVue
        v-for="product in filteredProducts"
        :key="product.id"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import ProductVue from '@/components/ProductVue.vue'
import { mapGetters, mapState } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
  name: 'ProductsView',
  data () {
    return {
      loading: true
    }
  },

  components: { ProductVue, PageLoader },
  computed: {
    ...mapState({
      products: (state) => state.products.products,
      categories: (state) => state.categories.categories
    }),
    ...mapGetters({
      getCategoriesBySlug: 'categories/getBySlug'
    }),
    filteredProducts () {
      return this.products.filter((product) =>
        this.$route.query.category ? product.categoriesIds.includes(this.getCategoriesBySlug(this.$route.query.category).id) : true
      )
    }
  },
  created () {
    if (!this.products.length) {
      this.loading = false
      this.$store.dispatch('products/fetchAll').finally(() => { this.loading = true })
    }
    if (!this.categories.length) {
      this.loading = false
      this.$store.dispatch('categories/fetchAll').finally(() => { this.loading = true })
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  min-height: 80vh;
}

</style>
