<template>
  <div>
    <vueper-slides dir="ltr" autoplay :touchable="true" fixed-height="640px" slide-image-inside>
      <vueper-slide
        v-for="(slide, i) in sliders"
        :key="i"
        :image="slide.image"
        :title="slide.title"
        :content="slide.content"
        :style="'background-color: rgba(20, 20, 34, 0.4)'"
      />
    </vueper-slides>

    <!-- ratting -->
    <section class="ratting d-flex justify-content-around align-item-center rounded-4">
      <div class="box">
        <img src="@/assets/images/quality.png" alt="" width="50" />
        <span class="text">{{$t('best-quality')}}</span>
      </div>

      <div class="box">
        <img src="@/assets/images/quality (1).png" alt="" width="50" />
        <span class="text">{{$t('best-standards')}}</span>
      </div>

      <div class="box">
        <img src="@/assets/images/testing.png" alt="" width="50" />
        <span class="text">{{$t('products-testing')}}</span>
      </div>

      <div class="box">
        <img src="@/assets/images/best-product.png" alt="" width="50" />
        <span class="text">{{$t('best-price')}}</span>
      </div>

      <div class="box">
        <img src="@/assets/images/team.png" alt="" width="50" />
        <span class="text">{{$t('having-experience')}}</span>
      </div>
    </section>

    <!-- About Us-->
    <section class="about container py-4 d-flex mx-auto w-75 m-0">
      <div class="row">
        <div class="col-lg-6 position-relative">
          <h2 class="fs-5 fw-light text-secondary">{{$t('about-walaain')}}</h2>
          <h3 class="fs-2 fw-bold title-3">25 {{$t('years-of-experience')}}</h3>
          <div class="about-text ck-content" v-html="aboutus.find(section => section.key === 'about')?.value"></div>
            <router-link :to="{ name: 'about' }" class="btn btn-info text-white">{{$t('more-about-us')}}</router-link>
        </div>
        <div class="video col-lg-6">
          <div class="position-relative w-100 pt-5">
            <video loop muted autoplay controls class="rounded-4 w-100 h-100">
              <source
                :src="videos.length ? baseStreamUrl + videos[0].video.id : null"
                type="video/mp4"
              />
              <!-- <source src="/video/Home.mp4" type="video/mp4" /> -->
            </video>
          </div>
        </div>
      </div>
    </section>``

    <!-- Products -->
    <section>
      <div class="image-overlay d-flex flex-wrap justify-content-center align-item-center text-center specifics">
        <div class="row statistics container mx-auto ">
          <div class="col-lg-3 col-m-6 col-sm-6 col-6 d-flex flex-column justify-content-center align-item-center">
            <span class="fs-1 text-white fw-semibold">20 <sup>TH</sup> </span>
            <span class="fw-semibold fs-6 text-white">{{$t('years-experience')}}</span>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 d-flex flex-column justify-content-center align-item-center">
            <span class="fs-1 text-white fw-semibold">5k <sup>+</sup></span>
            <span class="fw-semibold fs-6 text-white">{{$t('happy-client')}}</span>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 d-flex flex-column justify-content-center align-item-center">
            <span class="fs-1 text-white fw-semibold">96 <sup>+</sup></span>
            <span class="fw-semibold fs-6 text-white">{{$t('shipment')}}</span>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 d-flex flex-column justify-content-center align-item-center">
            <span class="fs-1 text-white fw-semibold">100 <sup>+</sup></span>
            <span class="fw-semibold fs-6 text-white">{{$t('employee')}}</span>
          </div>
        </div>
      </div>
      <div class="container mx-auto products">
        <div id="card" class="card border-0 rounded-4 bg-white d-flex mx-auto px-4">
          <h2 class="fs-5 fw-light text-secondary text-center pt-5">{{$t('our-products')}}</h2>
          <h3 class="fs-2 fw-bold text-center title-3">{{$t('cotton-yarns-spinning')}}</h3>
          <div class="row pb-4 py-lg-4">
            <ProductVue v-for="product in products.filter((product) => product.isFeatured === true)" :key="product.id" :product="product" :id="product.id" />
          </div>
          <router-link class="btn btn-info w-0 mx-auto text-white mb-4" :to="{ name: 'products' }">{{$t('see-more-products')}}</router-link>
        </div>
      </div>
    </section>

    <!-- News -->
    <section class="container">
      <h2 class="fs-5 fw-light text-secondary text-center">{{$t('our-news')}}</h2>
      <h3 class="fs-2 fw-bold text-center title-3">{{$t('whats-news')}}</h3>
      <div class="row d-flex justify-content-center align-item-center pt-2">
        <NewsVue v-for="oneNews in news.slice(0,3)" :key="oneNews.id" :oneNews="oneNews" />
      </div>
    </section>

    <!-- Contact Us -->
    <section class="contact-us">
      <div class="container">
        <div class="card border-0 rounded-4 bg-white d-flex px-4">
          <div class="row pt-lg-5 pt-4">
            <div class="col-md-6 order-lg-1 order-md-1 order-sm-2 order-2">
              <form class="px-4">
                <div class="form-group">
                  <label class="label">{{$t('your-name')}}</label>
                  <input v-model="name" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('full-name')">
                </div>
                <div class="form-group">
                  <label class="label">{{$t('your-email')}}</label>
                  <input v-model="email" type="email" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('email-address')">
                </div>
                <div class="form-group">
                  <label class="label">{{$t('type')}}</label>
                  <input v-model="type" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('select-type')">
                </div>
                <div class="form-group">
                  <label class="label">{{$t('your-message')}}</label>
                  <textarea v-model="message" class="form-control mb-4 mt-1 rounded-3 py-2" :style="$i18n.locale === 'ar' ? 'direction: rtl' : 'direction:ltr'" :placeholder="$t('message')"></textarea>
                </div>
                <a @click="submit()" class="btn btn-info w-0 mx-auto text-white mb-4">{{$t('send')}}</a>
              </form>
            </div>
            <div class="col-md-6 order-lg-2 order-md-2 order-sm-1 order-1 ps-4 pb-4">
              <h2 class="fs-5 fw-light text-secondary">{{$t('contact-us')}}</h2>
              <h3 class="fs-2 fw-bold mb-6 title-3">{{$t('get-touch-with-us')}}</h3>
              <h4 class="details">{{$t('customer-service')}}</h4>
              <span class="details-info">{{ branches?.contacts?.phone }}</span>
              <h4 class="details">{{$t('address')}}</h4>
              <span class="details-info">{{ branches?.contacts?.address }}</span>
              <h4 class="details">{{$t('opening-hours')}}</h4>
              <span class="details-info">{{ branches?.openHours }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { mapState } from 'vuex'
import ProductVue from '../components/ProductVue.vue'
import NewsVue from '../components/NewsVue.vue'
import { BASE_STREAM_URL } from '@/constants'

export default {
  name: 'HomeView',
  components: { VueperSlides, VueperSlide, ProductVue, NewsVue },
  data () {
    return {
      baseStreamUrl: BASE_STREAM_URL,

      name: null,
      email: null,
      type: null,
      message: null,
      loading: false
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('contactus/sendFeedback', { name: this.name, email: this.email, type: this.type, body: this.message })
    }
  },

  computed: {
    ...mapState({
      aboutus: (state) => state.aboutus.aboutus,
      products: (state) => state.products.products,
      news: (state) => state.news.news,
      sliders: (state) => state.sliders.sliders,
      branches: (state) => state.contactus.branches[0],
      videos: (state) => state.albums.albums.filter((t) => t.video.id)
    })
  }
}
</script>

<style lang="scss" scoped>

// ratting style
.ratting {
    background-color: var(--bs-primary);
    margin: 0 auto;
    width: 75%;
    transform: translateY(-50%);
    position: relative;
    text-align: center;
    z-index: 2;
    .box {
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      .text {
        padding-top: 10px;
        font-weight: bold;
      }
    }
}
.about-text {
  font-size: 13px;
  // line-height: 1.;
}

// Products
.image-overlay{
    height: 18em;
    background: linear-gradient(
      rgba(23, 20, 41, 0.5),
      rgba(23, 20, 41, 0.5)
    ),
    url('@/assets/images/Factory pics/IMG_3143.jpg') no-repeat center center;
}
.products {
  transform: translateY(-12%);
  .card {
    box-shadow:  0 .25rem .75rem rgba(black, .2);
  }
}

// Contact Us
.contact-us {
  transform: translateY(15%);
  .card {
    box-shadow:  0 .25rem .75rem rgba(black, .2);
  }
  .details {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 25px;
  }
  .details-info {
    font-size: 12px;
    color: #bebebe;
  }
  .label {
    font-size: 12px;
    margin-left: 5px;
  }
  input::placeholder {
    font-size: 12px;
  }
  textarea::placeholder {
    font-size: 12px;
  }
}

// responsive
@media (max-width: 992px) {
  .ratting {
    justify-content: center !important;
  }
  .about {
    text-align: center;
  }
  .products {
    .card {
    box-shadow: none;
    border: solid 1px #bebebe !important;
    }
    transform: translateY(0);
    margin: 2rem 0;
    .product {
      padding-top: 2rem;
    }
  }
  .contact-us {
    transform: translateY(0);
    margin: 2rem 0;
    .col-md-6 {
      .title-3 {
        margin: 0 !important;
      }
    }
    .card {
      box-shadow: none ;
      border: solid 1px #bebebe !important;
    }
  }
}

@media (max-width: 768px) {
  .vueperslides__bullet .default {
    margin-bottom: 3rem;
}
  .ratting {
    display: flex;
    flex-wrap: wrap;
    transform: translateY(0);
    margin-top: 2rem;
    background-color: transparent;
    width: 100% !important;
    margin: 0 !important;
    .box {
      color: var(--bs-primary);
    }
  }

  .news {
    padding-top: 2rem;
  }

}

@media (max-width: 578px) {
  .container {
    width: 75% !important;
    margin: auto inherit;
  }
  h1 {
    font-size: 2rem !important;
  }
}
</style>

<style lang="scss">
// slider style
.vueperslides__bullet .default {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    box-shadow: none;
    transition: 0.3s;
    width: 13px;
    height: 13px;
    margin-bottom: 6rem;
}

.vueperslides__bullet--active .default {
    background-color: var(--bs-secondary);
}

.vueperslides__bullet span {
    display: block;
    color: #fff;
    font-size: 10px;
    opacity: 0.8;
}

.vueperslide__image {
    z-index: -1;
}
</style>
