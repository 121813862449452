<template>
  <div class="product container pt-7 pb-5">
    <PageLoader v-if="!loading" />
    <h1 class="pb-3">{{$t('products')}}</h1>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="image">
          <img :src="getProductBySlug(this.$route.params.slug)?.primaryImage?.url" class="w-100 rounded-5 border border-1" alt="product">
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <h2>{{ getProductBySlug(this.$route.params.slug)?.name }}</h2>
        <div v-html="getProductBySlug(this.$route.params.slug)?.description" class="ck-content"></div>
      </div>
    </div>
    <div class="row pt-5">
      <h2>{{$t('related-products')}}</h2>
      <ProductVue v-for="product in products.filter((t) =>  !(t == getProductBySlug(this.$route.params.slug)))" :key="product.id" :product="product"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ProductVue from '../components/ProductVue.vue'
import PageLoader from '@/components/PageLoader.vue'
export default {
  name: 'ProductView',
  components: { ProductVue, PageLoader },
  data () {
    return {
      loading: true
    }
  },
  computed: {

    ...mapState({
      products: (state) => state.products.products
    }),

    ...mapGetters({
      getProductBySlug: 'products/getBySlug'
    })
  },
  created () {
    if (!this.products.length) {
      this.loading = false
      this.$store.dispatch('products/fetchAll').finally(() => { this.loading = true })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
