export const STORE_ABOUT = 'STORE_ABOUT'

export const STORE_PRODUCTS = 'STORE_PRODUCTS'
export const STORE_ONE_PRODUCT = 'STORE_ONE_PRODUCT'

export const STORE_NEWS = 'STORE_NEWS'
export const STORE_ONE_NEWS = 'STORE_ONE_NEWS'

export const STORE_ALBUMS = 'STORE_ALBUMS'
export const STORE_ONE_ALBUM = 'STORE_ONE_ALBUM'

export const STORE_SLIDERS = 'STORE_SLIDERS'

export const STORE_BRANCHES = 'STORE_BRANCHES'

export const STORE_CATEGORIES = 'STORE_CATEGORIES'
export const STORE_CATEGORIES_TREE = 'STORE_CATEGORIES_TREE'

export const STORE_ONE_CATEGORY = 'STORE_ONE_CATEGORY'
