import axios from '@/axios'
import * as types from '../mutation-types'

export default {
  namespaced: true,

  state: {
    categories: [],
    categoriesTree: []
  },

  mutations: {
    [types.STORE_CATEGORIES] (state, categories) {
      state.categories = categories
    },
    [types.STORE_CATEGORIES_TREE] (state, categoriesTree) {
      state.categoriesTree = categoriesTree
    }
  },

  actions: {
    fetchAll ({ commit }) {
      return axios.get('/categories').then((response) => {
        commit(types.STORE_CATEGORIES, response.data)
      })
    },
    fetchTree ({ commit }) {
      return axios.get('/categories/hierarchy').then((response) => {
        commit(types.STORE_CATEGORIES_TREE, response.data)
      })
    },

    fetchById ({ commit }, { id }) {
      return axios.get(`/categories/${id}`).then((response) => {
        commit(types.STORE_ONE_CATEGORY, response.data)
      })
    }
  },

  getters: {
    getById: (state) => (id) => state.categories.find((t) => t.id === id),
    getBySlug: (state) => (slug) => state.categories.find((t) => t.slug === slug)
  }
}
