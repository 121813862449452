<template>
    <div class="report container">
        <div class="row pt-7 pb-4">
          <div class="col-lg-8 col-md-12 rounded-3 py-3 mb-lg-4">
            <h1 class="mb-3">{{ getReportBySlug($route.params.slug)?.title }}</h1>
            <img :src="image ? image : getReportBySlug($route.params.slug)?.primaryImage.url " alt="" class="w-100 rounded-4 mb-3" height="400px" />
            <div class="row mb-4">
              <div v-for="image in images" :key="image.id" class="col-md-2 col-2">
                <img :src="image.url" @click="changeImage(image.url)" class="w-100 h-100 rounded-2 image mt-2" alt="">
              </div>
            </div>
            <p v-html="getReportBySlug($route.params.slug)?.content" class="fs-5"></p>
          </div>
          <div class="col-lg-4 col-md-12 py-3">
            <div class="row">
              <h1 class="mb-4">{{$t('related-news')}}</h1>
              <ReportCard v-for="oneNews in news.filter((t) =>  !(t == getReportBySlug($route.params.slug)))" :key="oneNews.id" :oneNews="oneNews"/>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ReportCard from '@/components/ReportCard.vue'

export default {
  name: 'ReportView',
  components: { ReportCard },
  data () {
    return {
      image: ''
    }
  },
  methods: {
    changeImage (url) {
      this.image = url
    }
  },
  computed: {
    ...mapState({
      news: (state) => state.news.news
    }),
    ...mapGetters({
      getReportBySlug: 'news/getBySlug'
    }),
    images () {
      return this.getReportBySlug(this.$route.params.slug)?.images
    }
  },
  watch: {
    '$route.params.slug' () {
      this.image = ''
    }
  },
  created () {
    if (!this.news.length) {
      this.$store.dispatch('news/fetchAll')
    }
  }
}
</script>

<style lang='scss' scoped>
  .content {
    font-size: 13px;
    .title {
      font-size: 16px;
    }
  }
  .image:hover {
    cursor: pointer;
  }
</style>
