<template>
  <div class="gallery container pt-7 pb-5">
    <PageLoader v-if="!loading"/>
    <h1 class="ms-2">{{$t('gallery')}}</h1>
    <div class="row">
      <AlbumVue v-for="album in albums" :key="album.id" :album="album"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AlbumVue from '../components/AlbumVue.vue'
import PageLoader from '@/components/PageLoader.vue'

export default {
  name: 'GalleryView',
  data () {
    return {
      loading: true
    }
  },
  components: { AlbumVue, PageLoader },
  computed: {
    ...mapState({
      albums: (state) => state.albums.albums
    })
  },

  created () {
    if (!this.albums.length) {
      this.loading = false
      this.$store.dispatch('albums/fetchAll').finally(() => { this.loading = true })
    }
  }

}
</script>

<style>
.gallery {
  min-height: 80vh;
}
  @media (max-width: 578px) {
    .gallery {
        width: 75% ;
        margin: 0 auto;
    }
  }
</style>
