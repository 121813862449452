<template>
    <div class="about pt-7">
        <PageLoader v-if="!loading" />
        <div class="container">
            <h1 class="fw-bold pb-3">{{$t('more-about-us')}}</h1>
            <h3 class="fs-2 fw-bold title-3 mb-3">25 {{$t('years-of-experience')}}</h3>
            <div class="about-text ck-content" v-html="aboutus.find(section => section.key === 'about')?.value"></div>
            <h3 class="fs-2 fw-bold title-3 mb-3">{{$t('quality-management')}}</h3>
            <div class="about-text ck-content" v-html="aboutus.find(section => section.key === 'mission')?.value"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PageLoader from '@/components/PageLoader'
export default {
  name: 'AboutView',
  components: { PageLoader },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      aboutus: (state) => state.aboutus.aboutus
    })
  },
  created () {
    if (!this.aboutus.length) {
      this.loading = false
      this.$store.dispatch('aboutus/fetchAll').finally(() => { this.loading = true })
    }
  }
}
</script>
<style>
.about {
  min-height: 80vh;
}
</style>
