<template>
  <footer class="footer bg-primary pt-2 pt-lg-6 pb-lg-4 pb-2">
    <div class="container">
      <div class="row border-bottom border-secondary">
        <div class="col-md-4 px-3 pb-3">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/images/logo-en.png" alt="logo" height="100">
          </router-link>
          <div class="footer-text about-footer" v-html="aboutus.find(section => section.key === 'about')?.value"></div>
        </div>
        <div class="col-md-4 pt-lg-5 pt-md-5">
          <h5 class="footer-text">{{$t('quick-link')}}</h5>
          <div class="row">
            <div class="col-md-4">
              <router-link class="footer-text footer-link text-decoration-none" :to="{ name: 'home' }">{{$t('home')}}</router-link>
            </div>
            <div class="col-md-4">
              <router-link class="footer-text footer-link text-decoration-none" :to="{ name: 'products' }">{{$t('products')}}</router-link>
            </div>
            <div v-if="false" class="col-md-4">
              <router-link class="footer-text footer-link text-decoration-none" :to="{ name: '' }" >Cotton yarns</router-link>
            </div>
            <div v-if="false" class="col-md-4">
              <router-link v-if="false" class="footer-text footer-link text-decoration-none" :to="{ name: '' }">Cotton clothes</router-link>
            </div>
            <div class="col-md-4">
              <router-link class="footer-text  footer-link text-decoration-none" :to="{ name: 'news' }">{{$t('news')}}</router-link>
            </div>
            <div class="col-md-4">
              <router-link class="footer-text footer-link text-decoration-none" :to="{ name: 'gallery' }">{{$t('gallery')}}</router-link>
            </div>
            <div class="col-md-4">
              <router-link class="footer-text footer-link text-decoration-none" :to="{ name: 'about' }">{{$t('about')}}</router-link>
            </div>
          </div>
        </div>
        <!-- follow us -->
        <div class="col-md-4 ">
          <h5 class="footer-text pt-lg-5 pt-4 text-center">{{$t('follow-us')}}</h5>
          <div class="d-flex gap-3 justify-content-center mb-2">
            <div>
              <a class="text-decoration-none" :href="branches[0].contacts.facebook" target="_blank">
                <i class="icon fa-brands fa-facebook-f fa-sm"></i>
              </a>
            </div>
            <div>
              <a class="text-decoration-none" :href="branches[0].contacts.instagram" target="_blank">
                <i class="icon fa-brands fa-instagram fa-sm"></i>
              </a>
            </div>
            <div>
              <a class="text-decoration-none" :href="branches[0].contacts.linkedIn" target="_blank">
                <i class="icon fa-brands fa-linkedin-in fa-sm"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-3 text-center mt-lg-4 mt-2">
          <span class="footer-text">Alwalaain &copy; All right Reserved Design by<a class="text-white text-decoration-none" href="https://beetronix.com" target="_blank"> BEETRONIX</a>
          </span>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'FooterVue',
  computed: {
    ...mapState({
      aboutus: (state) => state.aboutus.aboutus
    })
  },
  props: ['branches']

}
</script>

<style lang="scss">
.footer {
  .footer-text {
    font-size: 12px;
    color: white;
    }
  .footer-link:hover {
    color: var(--bs-info);
  }

  .about-footer {
    *:not(:first-child) {
      display: none;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: var(--bs-info);
    height: 25px;
    width: 25px;
    font-weight: lighter;
    border-radius: 50%;
  }
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
    .icons {
      padding-left: 12px !important;
      padding-bottom: 2rem;
      .d-flex {
        justify-content: center;
      }
    }
  }
}
</style>
