<template>
  <div class="col-lg-3 col-md-6 col-sm-6 text-center product">
    <router-link class="text-decoration-none" :to="{ name: 'product', params: { slug: product.slug} }">
      <img class="border border-1 w-100 rounded-4" :src="product.primaryImage?.url" alt="product">
      <span class="fw-semibold">{{ product.name }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProductVue',
  props: ['product']
}
</script>

<style>

</style>
