import axios from '@/axios'
import * as types from '../mutation-types'

export default {
  namespaced: true,
  state: {
    sliders: []
  },

  actions: {
    fetchAll ({ commit }) {
      return axios.get('/sliders').then((response) => {
        commit(types.STORE_SLIDERS, response.data)
        return response.data
      })
    }
  },

  mutations: {
    [types.STORE_SLIDERS] (state, sliders) {
      state.sliders = sliders
    }
  },

  getters: {
    getById: (state) => (id) => state.sliders.find((t) => t.id === id)
  }
}
